import React from 'react';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import { Text } from 'components';
import Icon from 'assets/icons/icons.svg';
import Julia from 'assets/images/Julia.png';
import Tim from 'assets/images/Tim.png';
import Daniel from 'assets/images/Daniel.png';
import Steph from 'assets/images/Steph.png';
import Kat from 'assets/images/Kat.png';
import Lilly from 'assets/images/Lilly.png';
import StephPic from 'assets/images/StephPic.png';
import DanielPic from 'assets/images/DanielPic.png';
import CaseStudies from '../start/results/components/CaseStudies';
import Layout from 'layouts/Layout';
import Start from 'pages/start';
import Button from 'pages/start/results/components/StartNowButton';
import { useRouter } from 'apis/history';

const Item = {
  type: 'caseStudiesSection',
  title: 'Case studies',
  startBtnTitle: 'Get my plan',
  slides: [
    {
      user: {
        name: 'Lily',
        img: 'funnel/reviews/Lily.jpg',
      },
      img: 'funnel/reviews/Lily_review.png',
      imgMobile: 'funnel/reviews/Lily_review.png',
    },
    {
      user: {
        name: 'Kate',
        img: 'funnel/reviews/Kate.jpg',
      },
      img: 'funnel/reviews/Kate_review.png',
      imgMobile: 'funnel/reviews/Kate_review.png',
    },
    {
      user: {
        name: 'Andy',
        img: 'funnel/reviews/Andy.jpg',
      },
      img: 'funnel/reviews/Andy_review.png',
      imgMobile: 'funnel/reviews/Andy_review.png',
    },
    {
      user: {
        name: 'Louis',
        img: 'funnel/reviews/Louis.jpg',
      },
      img: 'funnel/reviews/Louis_review.png',
      imgMobile: 'funnel/reviews/Louis_review.png',
    },
    {
      user: {
        name: 'Anne',
        img: 'funnel/reviews/Anne.jpg',
      },
      img: 'funnel/reviews/Anne_review.png',
      imgMobile: 'funnel/reviews/Anne_review.png',
    },
    {
      user: {
        name: 'David',
        img: 'funnel/reviews/David.jpg',
      },
      img: 'funnel/reviews/David_review.png',
      imgMobile: 'funnel/reviews/David_review.png',
    },
    {
      user: {
        name: 'Clara',
        img: 'funnel/reviews/Clara.jpg',
      },
      img: 'funnel/reviews/Clara_review.png',
      imgMobile: 'funnel/reviews/Clara_review.png',
    },
    {
      user: {
        name: 'Anika',
        img: 'funnel/reviews/Anika.jpg',
      },
      img: 'funnel/reviews/Anika_review.png',
      imgMobile: 'funnel/reviews/Anika_review.png',
    },
    {
      user: {
        name: 'Justin',
        img: 'funnel/reviews/Justin.jpg',
      },
      img: 'funnel/reviews/Justin_review.png',
      imgMobile: 'funnel/reviews/Justin_review.png',
    },
  ],
};

const Reviews = () => {
  const { isTablet } = useQuery();

  const { goToLanding } = useRouter();

  return (
    <Layout
      seoTitle="Reviews"
      seoDescription="Thousands of happy customers worldwide"
      seoName="Reviews"
    >
      <StudyWrapper>
        <StudyContainer>
          <StudyChecklist>
            <ReviewTitle>
              Brainway <ReviewTitleStrong>reviews</ReviewTitleStrong>
            </ReviewTitle>
            <ReviewParagraph>
              Eliminate procrastination, improve focus, manage overwhelm, and
              create your ultimate routine with Brainway
            </ReviewParagraph>
            {!isTablet ? (
              <ColumnContainer>
                <ChecklistColumn>
                  <ColumnTitle>Productivity</ColumnTitle>
                  <Column>
                    <ColumnItem>Boost focus</ColumnItem>
                    <ColumnItem>Uncover distractions</ColumnItem>
                    <ColumnItem>Eliminate procrastination</ColumnItem>
                    <ColumnItem>Break bad habits</ColumnItem>
                    <ColumnItem>Build new better habits</ColumnItem>
                    <ColumnItem>Improve motivation</ColumnItem>
                    <ColumnItem>Organize your time</ColumnItem>
                    <ColumnItem>Increase energy levels</ColumnItem>
                  </Column>
                </ChecklistColumn>
                <ChecklistColumn>
                  <ColumnTitle>Mental Health</ColumnTitle>
                  <Column>
                    <ColumnItem>Be more mindful</ColumnItem>
                    <ColumnItem>Reduce anxiety</ColumnItem>
                    <ColumnItem>Boost self-esteem</ColumnItem>
                    <ColumnItem>Control ADHD</ColumnItem>
                    <ColumnItem>Decrease stress</ColumnItem>
                    <ColumnItem>Take control of burnout</ColumnItem>
                    <ColumnItem>Improve your sleep</ColumnItem>
                    <ColumnItem>Discover triggers</ColumnItem>
                  </Column>
                </ChecklistColumn>
              </ColumnContainer>
            ) : null}
          </StudyChecklist>
          <CaseStudiesWrap>
            <CaseStudies
              slides={Item.slides}
              flexDirection={'column'}
              alignItems="center"
              bgHex="#FFF"
              infoStyle={true}
            />
          </CaseStudiesWrap>
        </StudyContainer>
      </StudyWrapper>
      {isTablet && (
        <>
          <StartQuizBox>
            <Text type="h2400" textAlign="center" color="dark100">
              Get <strong>Brainway</strong>
            </Text>

            <Text textAlign="center" color="dark80">
              Eliminate procrastination, improve focus, and build your ultimate
              routine
            </Text>

            <Button onClick={() => goToLanding()}>START THE QUIZ</Button>
          </StartQuizBox>
        </>
      )}
      <UsersContainer>
        <ShareContainer>
          <UsersTitle>What our users are saying...</UsersTitle>
          <UsersWrapper>
            <ReviewItem>
              <ReviewUser>
                <ReviewSource>
                  <svg>
                    <use xlinkHref={`#sfb`} />
                  </svg>
                  <Icon />
                </ReviewSource>
                <ReviewImageContainer>
                  <ReviewImage src={Julia} />
                </ReviewImageContainer>
                <Author>Julia Lewis</Author>
                <AuthorText>
                  I definitely recommend{' '}
                  <EmailLink target="_blank" href="https://brainway.app/">
                    @brainway.app
                  </EmailLink>{' '}
                  if you enjoy keeping track of your daily activities, including
                  your social interactions, the weather, who you&apos;re with at
                  work, and your mood as well as your physical and mental
                  health. Probably the most customizable tracking and insight
                  app available today, that is able to show you how your daily
                  activities affect your health for the better!
                </AuthorText>
              </ReviewUser>
            </ReviewItem>
            <ReviewItem>
              <ReviewUser>
                <ReviewSource>
                  <svg>
                    <use xlinkHref={`#sfb`} />
                  </svg>
                  <Icon />
                </ReviewSource>
                <ReviewImageContainer>
                  <ReviewImage src={Tim} />
                </ReviewImageContainer>
                <Author>Timothy Young</Author>
                <AuthorText>
                  Trying to pay more attention to my mood swings and anxiety,
                  but I always need some guidance. I downloaded the{' '}
                  <EmailLink target="_blank" href="https://brainway.app/">
                    @brainway.app
                  </EmailLink>
                  , and so far I&apos;m really enjoying how comprehensive and
                  useful it is. I just need to make a few simple clicks every
                  day and you get amazing life-changing insights!
                </AuthorText>
              </ReviewUser>
            </ReviewItem>
            <ReviewItem>
              <ReviewUser>
                <ReviewSource>
                  <svg>
                    <use xlinkHref={`#stw`} />
                  </svg>
                  <Icon />
                </ReviewSource>
                <ReviewImageContainer>
                  <ReviewImage src={Daniel} />
                </ReviewImageContainer>
                <Author>Daniel Hill</Author>
                <AuthorText>
                  We did a 5-months sleep experiment and here&apos;s the
                  screenshot of our results. We both know what to do to feel
                  better in the morning now! You can fully customize your
                  factor, add emojis or photos for every single thing, and track
                  practically any health measurement in the{' '}
                  <EmailLink target="_blank" href="https://brainway.app/">
                    @brainway.app
                  </EmailLink>
                  .
                </AuthorText>
                <StyledImg src={DanielPic} />
              </ReviewUser>
            </ReviewItem>
            <ReviewItem>
              <ReviewUser>
                <ReviewSource>
                  <svg>
                    <use xlinkHref={`#stw`} />
                  </svg>
                  <Icon />
                </ReviewSource>
                <ReviewImageContainer>
                  <ReviewImage src={Steph} />
                </ReviewImageContainer>
                <Author>Stephen Bailey</Author>
                <AuthorText>
                  I tracked what helps my focus over the last 3 months with{' '}
                  <EmailLink target="_blank" href="https://brainway.app/">
                    @brainway.app
                  </EmailLink>{' '}
                  as it was getting out of hand most of the time at work. Just
                  take a look at this...
                </AuthorText>
                <StyledImg src={StephPic} />
              </ReviewUser>
            </ReviewItem>
            <ReviewItem>
              <ReviewUser>
                <ReviewSource>
                  <svg>
                    <use xlinkHref={`#stw`} />
                  </svg>
                  <Icon />
                </ReviewSource>
                <ReviewImageContainer>
                  <ReviewImage src={Kat} />
                </ReviewImageContainer>
                <Author>Katie Green</Author>
                <AuthorText>
                  Shoutout to the team{' '}
                  <EmailLink target="_blank" href="https://brainway.app/">
                    @brainway.app
                  </EmailLink>
                  ! A very impressive tool that helped me understand how my
                  behavior & ADHD symptoms are related. Kudos to you!
                </AuthorText>
              </ReviewUser>
            </ReviewItem>
            <ReviewItem>
              <ReviewUser>
                <ReviewSource>
                  <svg>
                    <use xlinkHref={`#sreddit`} />
                  </svg>
                  <Icon />
                </ReviewSource>
                <ReviewImageContainer>
                  <ReviewImage src={Lilly} />
                </ReviewImageContainer>
                <Author>Lilly Owens</Author>
                <AuthorText>
                  By far,{' '}
                  <EmailLink target="_blank" href="https://brainway.app/">
                    @brainway.app
                  </EmailLink>{' '}
                  is the best app available for tracking mood, sleep, and
                  chronic pain symptoms. Glad I&apos;ve found it!
                </AuthorText>
              </ReviewUser>
            </ReviewItem>
          </UsersWrapper>
        </ShareContainer>
      </UsersContainer>
      <ShareSection>
        <ShareContainer>
          <ShareWrapper>
            <ShareLeft>
              <ShareTitle>Share your inspiring story with us!</ShareTitle>
            </ShareLeft>
            <ShareRight>
              <ShareText>
                Have a story you want to share with others? We would love to
                hear how Brainway helped you improve your health & wellbeing!
                Submit your stories to{' '}
                <EmailLink href="mailto:hello@brainway.app">
                  hello@brainway.app{' '}
                </EmailLink>
                .
              </ShareText>
            </ShareRight>
          </ShareWrapper>
        </ShareContainer>
      </ShareSection>
    </Layout>
  );
};

export default Reviews;

const LinkWrapper = styled.a`
  opacity: unset;
`;

const EmailLink = styled.a`
  color: #f80 !important;
  font-weight: 500;
  cursor: pointer;
`;

const CaseStudiesWrap = styled.div`
  @media ${tablet} {
    width: 100%;
  }
`;
const StartQuizBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-conten: center;
  align-items: center;
  padding: 3rem 1rem;
  gap: 2rem;
  background: #fcf7f1;
`;

const ReviewTitleStrong = styled.strong`
  font-weight: 600;
`;

const StudyWrapper = styled.div`
  margin: auto;
  max-width: 100%;
  padding: 0 32px;
  width: 1204px;
  @media ${tablet} {
    padding: 0 1rem;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ColumnItem = styled.li`
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24'><path d='M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17Z' fill='%23F87666'/></svg>")
    no-repeat 0 0;
  font-size: 16px;
  letter-spacing: -0.024em;
  line-height: 140%;
  margin-bottom: 18px;
  padding-left: 40px;
  font-family: Inter;
`;

const Column = styled.ul`
  list-style: none;
`;

const ColumnTitle = styled(Text)`
  color: #555770;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.036em;
  line-height: 140%;
  margin: 28px 0 16px;
  text-transform: uppercase;
`;

const ChecklistColumn = styled.div`
  width: 47%;
`;

const ChecklistContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ReviewParagraph = styled(Text)`
  color: #28293d;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.024em;
  line-height: 140%;
  margin-bottom: 20px;
  -webkit-font-smoothing: antialiased;
  font-family: Inter;
  @media ${tablet} {
    text-align: center;
    font-size: 1rem;
    margin-bottom: unset;
  }
`;

const ReviewTitle = styled(Text)`
  color: #1c1c28;
  font-size: 38px;
  font-weight: 400;
  letter-spacing: -0.036em;
  line-height: 130%;
  margin: 10px 0 15px;
  font-family: Inter;
  @media ${tablet} {
    font-size: 26px;
    line-height: 130%;
    margin: 0 0 20px;
    text-align: center;
  }
`;

const StudyChecklist = styled.div`
  width: calc(50% - 48px);
  @media ${tablet} {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    padding: 0 1rem;
  }
`;

const StudyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const StyledImg = styled.img`
  border-radius: 16px;
  margin-top: 11px;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
`;

const Author = styled.div`
  font-weight: 600;
  padding-bottom: 2px;
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const AuthorText = styled(Text)`
  font-weight: 400;
  color: #fff;
  font-family: Inter;
  -webkit-font-smoothing: antialiased;
  letter-spacing: -0.024em;
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const ReviewImage = styled.img`
  border-radius: 50%;
  height: 50px;
  margin: -1px;
  -o-object-fit: cover;
  object-fit: cover;
  width: 50px;
  @media ${tablet} {
    height: 38px;
    width: 38px;
  }
`;

const ReviewImageContainer = styled.div`
  border-radius: 50%;
  height: 48px;
  left: 0;
  margin: 24px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 48px;
  @media ${tablet} {
    height: 36px;
    margin: 16px;
    width: 36px;
  }
`;

const ReviewSource = styled.div`
  height: 22px;
  margin: 24px;
  position: absolute;
  right: 0;
  top: 0;
  width: 22px;

  svg {
    max-width: 100%;
    fill: currentColor;
    height: auto;
    max-height: 100%;
    vertical-align: middle;
  }

  @media ${tablet} {
    height: 16px;
    margin: 16px 12px;
    width: 24px;
  }
`;

const ReviewUser = styled.div`
  background: #28293d;
  border-radius: 16px;
  box-shadow: 0 4px 32px rgba(28, 28, 40, 0.06);
  flex-grow: 1;
  margin-bottom: 32px;
  padding: 24px 24px 24px 84px;
  position: relative;
  @media ${tablet} {
    font-size: 12px;
    line-height: 140%;
    margin-bottom: 16px;
    padding: 16px 22px 16px 64px;
  }
`;

const ReviewItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 8px;
  width: calc(50% - 24px);
  @media ${tablet} {
    margin: 0;
    width: 100%;
  }
`;

const UsersWrapper = styled.div`
  justify-content: space-between;
  margin-bottom: -30px;
  display: flex;
  flex-wrap: wrap;
`;

const UsersTitle = styled(Text)`
  color: #fff;
  font-size: 38px;
  font-weight: 400;
  line-height: 130%;
  margin-bottom: 48px;
  text-align: center;
  @media ${tablet} {
    font-size: 26px;
    font-weight: 400;
    line-height: 130%;
    margin-bottom: 32px;
  }
`;

const UsersContainer = styled.div`
  background: #1c1c28;
  color: #fff;
  padding: 72px 0;
  @media ${tablet} {
    padding: 48px 0 64px;
  }
`;

const ShareSection = styled.div`
  padding: 72px 0;
  @media ${tablet} {
    padding: 48px 0;
    text-align: center;
  }
`;

const ShareContainer = styled.div`
  margin: auto;
  max-width: 100%;
  padding: 0 32px;
  width: 1204px;
  @media ${tablet} {
    padding: 0 1rem;
  }
`;

const ShareWrapper = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
`;

const ShareLeft = styled.div`
  width: calc(50% - 48px);
  @media ${tablet} {
    width: 100%;
  }
`;

const ShareRight = styled.div`
  width: calc(50% - 48px);
  @media ${tablet} {
    width: 100%;
  }
`;

const ShareTitle = styled(Text)`
  font-size: 32px;
  font-weight: 600;
  color: #222;
  letter-spacing: -0.036em;
  line-height: 140%;
  margin: 0;
  font-family: Inter;
  @media ${tablet} {
    font-size: 26px;
    line-height: 130%;
    margin-bottom: 24px;
  }
`;

const ShareText = styled(Text)`
  color: #28293d;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.024em;
  line-height: 140%;
  font-family: Inter;
  -webkit-font-smoothing: antialiased;
  @media ${tablet} {
    font-size: 16px;
    line-height: 140%;
  }
`;
